<template>
    <div>
        	<!-- 公共头部 -->
	<Header></Header>


        	<!-- 公共底部 -->
		<!-- <Footer></Footer> -->
        
    </div>
</template>

<script>
import Header from '@/components/Public/Header.vue';
	import Footer from '@/components/Public/Footer.vue';
export default {
    components: {
			Header,
			Footer
		},
    data(){
        return {
            title:"学习说明"
        }
    }
}
</script>

<style scoped>

</style>